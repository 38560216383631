/*
 *
 *   Styles for animated modal
 *   ========================= */

/* Start state */

#exampleModal {
  max-width: 550px;
  border-radius: 4px;
  padding: 40px;
  overflow: hidden;
  transform: translateY(-50px);
  transition: all .7s;
  img {
    width: 100%; }

  h2, p {
    transform: translateY(-50px);
    opacity: 0;
    transition-property: transform, opacity;
    transition-duration: .4s;
    margin-top: 20px;
    text-align: center; } }

/* Final state */

.fancybox-slide--current #exampleModal {
  transform: translateY(0);
  opacity: 1;

  h2, p {
    transform: translateY(0);
    opacity: 1; }

  h2 {
    transition-delay: .1s; }

  p {
    transition-delay: .3s; } }

/* Reveal content with different delays */
