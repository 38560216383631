.cus-product-wrap {
  padding: 30px 0;
  #main-content {
    img {
      width: 100%; }
    .custom-container {
      padding-left: 0;
      padding-right: 0; } }
  h3 {
    background-color: rgba(36, 42, 100, 0.9);
    color: #fff;
    margin: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: .5em;
    padding-right: .5em; }
  .menu-list.page_mobile_mecprod {
    h3 {
      margin-bottom: 25px;
      padding: 0;
      background: transparent;
      color: rgba(36,42,100,0.9); } }
  .product-info {
    h3 {
      background-color: transparent;
      color: rgba(36,42,100,0.9); }
    div {
      color: #444;
      font-size: 16px; } }
  .product-sample {
    b {
      color: #444;
      font-weight: 700;
      font-size: 16px; }
    p {
      color: #444;
      font-size: 16px; } }
  &.cate {
    padding: 40px 0;
    a.product-wrap {
      display: block;
      text-align: center; }
    .pic {
      margin-top: 40px; }
    .txt {
      margin-top: 5px; }
    span {
      color: #1A2731; } } }

#cus-sidebar {
  padding-left: 15px;
  padding-right: 15px; }

@media screen and (max-width: 767px) {
  .cus-product-wrap {
    .cus-col-caculate-7 {
      .cus-col-5, .cus-col-7, .cus-col-12 {
        width: 100%;
        padding-left: 5px;
        padding-right: 5px; }
      h3 {
        font-size: 20px;
        margin-top: 20px; } }
    &.cate {
      padding: 20px 0; } } }




