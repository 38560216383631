html, body {
  background: #fff; }
[data-bg] {
  width: 100%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

.custom-wrap {
  width: 100%;
  img {
    width: 100%; }

  > div {
    margin: 100px 0; } }

@media screen and (max-width: 767px) {
  .custom-wrap > div {
    margin: 15px 0; } }

.custom-container {
  width: 100%;
  max-width: 1280px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
  // z-index: 10
  position: relative; }

.custom-row {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  &.closest {
    padding-left: 15px;
    padding-right: 15px; }

  &.col {
    flex-direction: column; } }

.custom-container {
  [class^='cus-col-'] {
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    // padding-left: 30px
 }    // padding-right: 30px

  .custom-row.closest [class^='cus-col-'] {
    padding-left: 0;
    padding-right: 0; } }

.cus-col-3 {
  width: 25%; }

.cus-col-4 {
  width: 33.333333%; }

.cus-col-5 {
  width: 41.666667%; }

.cus-col-6 {
  width: 50%; }

.cus-col-7 {
  width: 58.333333%; }

.cus-col-8 {
  width: 66.666667%; }

.cus-col-9 {
  width: 75%; }

.cus-col-12 {
  width: 100%; }

.cus-col-caculate-3 {
  width: 30%; }
.cus-col-caculate-7 {
  width: 70%; }

@media screen and (max-width: 992px) {
  .cus-col-lg-5 {
    width: 41.666667%; }

  .cus-col-lg-7 {
    width: 58.333333%; } }

@media screen and (max-width: 767px) {
  // .custom-container
  //   padding-left: 30px
  //   padding-right: 30px
  .cus-col-md-6 {
    width: 50%; }

  .cus-col-md-12 {
    width: 100%; } }

@media screen and (max-width: 576px) {
  // .custom-container
  //   padding-left: 30px
  //   padding-right: 30px
  .cus-col-sm-6 {
    width: 50%; }

  .cus-col-sm-12 {
    width: 100%; } }



.custom-container.reverse .custom-row:nth-of-type(even) {
  flex-direction: row-reverse; }
.custom-container.odd-reverse .custom-row:nth-of-type(odd) {
  flex-direction: row-reverse; }

@media screen and (max-width: 767px) {
  .cus-order {
    order: -1; } }


// wp container
@media screen and (max-width: 767px) {
  .custom-container {
    padding-left: 0;
    padding-right: 0; } }

// button
.cus-more {
  display: block;
  padding: 20px 40px;
  width: 200px;
  background: #db362e;
  color: #fff;
  text-align: center;
  font-size: 20px;
  letter-spacing: 2px; }

// button ENDDDDDD

.swiper-container {
  img {
    width: 100%; }

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background: #fff;
    border: 1px solid #4c4d4c;
    &:focus {
      outline: none; } }
  .swiper-pagination-bullet-active {
    background: #4c4d4c;
    border: 1px solid #fff; } }

// custom-banner
#banner {
  .kc_column {
    padding-left: 0;
    padding-right: 0; } }
.custom-banner {
  position: relative;
  img {
    width: 100%;
    vertical-align: middle;
    height: auto; }
  h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    z-index: 10;
    text-align: center;
    span {
      font-size: 30px;
      color: #fff;
      font-weight: 500;
      line-height: 1.5; } }
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 2; } }

@media screen and (max-width: 767px) {
  .custom-banner {
    h2 {
      span {
        font-size: 20px; } } } }



// wp-setting
.kc-row-container.kc-container .kc-wrap-columns, .kc_row_inner {
  width: 100%; }
.kc-container {
  padding-left: 0;
  padding-right: 0; }
.kc-row-container.kc-container .kc-wrap-columns, .kc_row_inner {
  margin-left: 0;
  margin-right: 0; }
#side_menu {
  display: none; }
#products_slider {
  display: none; }

#cus-search {
  .kc-elm {
    margin-bottom: 15px;
    float: none; } }


#index_company {
  .kc_column, .kc_column_inner {
    padding-left: 0;
    padding-right: 0; } }





