#footer {
  padding: 0;
  background: transparent;
  &::after {
    content: '';
    display: block;
    clear: both; }
  .container {
    display: flex;
    flex-wrap: wrap;
    h1.logo {
      align-self: center; } }

  .contact_list {
    div {
      display: inline-block;
      width: 30px;
      margin-right: 20px;
      cursor: pointer;
      img {
        width: 100%; } } } }

