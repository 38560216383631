* {
  position: relative;
  font-family: "Microsoft JhengHei"; }

h1, h2, h3, h4, h5, h6, p {
  margin: 0; }

ul {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none; } }

// div[data = 'data-bg']
//   width: 100%
//   height: auto
//   background-size: cover
//   background-repeat: no-repeat
//   background-position: center center

.container {
  width: 100%;
  max-width: 1280px;
  padding: 0 15px; }

.page_container {
  padding: 70px 0; }

h2.title {
  margin-bottom: 50px;
  font-size: 40px;
  color: #242a64;
  text-align: center;
  letter-spacing: 5px;

  span {
    display: block;
    padding-top: 10px;
    font-size: 20px;
    text-transform: uppercase;
    color: #4c4d4c;
    letter-spacing: 2px; } }

.intro {
  h2 {
    font-size: 18px;
    color: #4c4d4c;

    img {
      display: inline-block;
      width: 32px;
      height: auto;
      margin-right: 10px; } }

  p {
    color: #4c4d4c;
    font-size: 16px;
    line-height: 25px; } }

.info {
  margin-bottom: 30px;

  p {
    color: #4c4d4c;
    font-size: 16px;
    line-height: 30px; } }

body, .module-complex .nav-tabs {
  display: none; }

.page_mobile_mecprod {
  .mpgtitle {
    border: none;

    h3 {
      background-color: rgba(36, 42, 100, 0.9);
      color: #fff; } }
  .maddtocart {
    display: none; } }

@media screen and (max-width: 600px) {
  .page_mobile_mecprod .mpgtitle {
    margin-top: 20px; } }

@media screen and (max-width: 600px) {
  .page_mobile_mecprod .mpgtitle h3 {
    font-size: 20px; } }

.product-sample {
  padding: 10px 0 0 0;
  line-height: 30px;

  .btns_row {
    padding-top: 10px;

    p {
      display: flex;
      align-items: center; }

    a {
      display: inline-block;
      margin-left: 20px;
      padding: 2px 10px;
      border-radius: 2px;
      text-decoration: none;
      color: #fff;
      background-color: rgba(36, 42, 100, 0.9); } } }

.product-info {
  background-color: #f7f7f7;
  padding: 30px;
  margin-top: 30px;

  h3 {
    color: rgba(36, 42, 100, 0.9);
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
    margin-top: 30px;

    &:first-child {
      margin-top: 0; } }

  .info {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;

    b, p {
      font-size: 16px;
      line-height: 20px; }

    b {
      display: block;
      width: 40%;
      padding-right: 10px; } } }

@media screen and (max-width: 600px) {
  .product-info {
    padding: 15px; } }

@media screen and (max-width: 600px) {
  .product-info h3 {
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 20px; } }

@media screen and (max-width: 600px) {
  .product-info .info {
    display: block;
    margin-bottom: 20px; } }

@media screen and (max-width: 600px) {
  .product-info .info {
    b, p {
      font-size: 14px; } } }

@media screen and (max-width: 600px) {
  .product-info .info b {
    margin-bottom: 5px; } }

@media screen and (max-width: 768px) {
  .page_container {
    padding: 30px 0; }

  h2.title {
    margin-bottom: 30px;
    font-size: 25px;

    span {
      font-size: 14px;
      letter-spacing: 0px; } } }

@media screen and (max-width: 600px) {
  .menu-list {
    position: fixed;
    left: -250px;
    transition-duration: 1s;
    top: 0;
    max-width: 250px;
    z-index: 100000;
    padding: 30px 10px !important;
    height: 100%;
    background-color: #fff; } }

.menu-list {
  .toggle-menu {
    position: absolute;
    left: 100%;
    top: 40%;
    background-color: rgba(36, 42, 100, 0.9);
    color: #fff;
    padding: 5px;
    width: 30px;
    text-align: center;
    cursor: pointer;
    display: none; }

  &.active {
    left: 0; } }

@media screen and (max-width: 600px) {
  .menu-list .toggle-menu {
    display: block; } }

.header {
  box-shadow: none;

  > .minner {
    max-width: 100%; } }

.module {
  margin-bottom: 0; }

.module-minicart, .langbar, .mnavbar {
  display: none; }

.footer {
  background-color: transparent;
  padding: 0; }

.wrap > .minner {
  padding: 0; }

.footer > .minner, .mcol.mcol > [id*="Dyn_"][id*="Dyn_"][id*="Dyn_"] {
  max-width: 100%; }

.go-pcurl {
  display: none; }

.mpgdetail {
  padding: 0; }

.wall_row {
  &:after {
    content: "";
    display: table;
    clear: both; }

  &:nth-of-type(1) .item {
    &:nth-of-type(1) .intro, &:nth-of-type(3) .intro {
      background-color: #242a64;
      opacity: 1; } }

  &:nth-of-type(2) .item:nth-of-type(2) .intro {
    background-color: #242a64;
    opacity: 1; }

  .item {
    display: block;
    float: left;
    height: 270px;
    background-position: center center;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.4); }

    &.c2 {
      width: 20%; }

    &.c3 {
      width: 30%; }

    .intro {
      padding-top: 50px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background-color: rgba(36, 42, 100, 0.6);
      opacity: 0;
      transition-duration: 0.3s;

      h2, p {
        color: #fff;
        width: 80%;
        margin: 0 auto;
        max-width: 250px; }

      h2 {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: solid 1px #fff;
        text-align: center;

        span {
          display: block; } }

      &.more {
        opacity: 1;
        background-color: #fff;

        a.more {
          position: absolute;
          bottom: 50%;
          right: 50%;
          transform: translate(50%, 50%);
          border: none;
          color: #4c4d4c;
          text-decoration: none;
          font-weight: bold;
          letter-spacing: 5px;

          &:after {
            content: "";
            display: block;
            position: absolute;
            left: 100%;
            bottom: 50%;
            transform: translateY(50%);
            margin-left: 10px;
            border: solid 8px transparent;
            border-left-color: #4c4d4c; } } } }

    &:hover .intro {
      opacity: 1; } } }

@media screen and (max-width: 768px) {
  .wall_row {
    padding: 0 30px;
    margin-bottom: 5px;

    .item {
      &.c2, &.c3 {
        width: 100%;
        margin: 0 auto;
        float: none; }

      &:nth-of-type(even) {
        margin-bottom: 30px; }

      &:last-of-type {
        height: 200px; }

      .intro {
        opacity: 1;

        &.more {
          border: solid 1px #4c4d4c;

          a.more {
            min-width: 0%; } } } } } }

#company {
  h2.title {
    color: #fff;

    span {
      color: #fff; } }

  .info p {
    color: #fff; }

  a.more {
    display: block;
    max-width: 150px;
    margin: 80px auto 0 auto;
    border: solid 1px #fff;
    color: #4c4d4c;
    background-color: #fff;
    text-transform: uppercase;
    padding: 10px 0;
    border-radius: 40px;
    text-decoration: none;
    letter-spacing: 5px;
    margin-top: 50px;
    text-align: center; } }

footer#footer {
  background-color: #f2f2f2;
  padding: 40px 0;

  h1.logo {
    display: inline-block;
    width: 145px;
    height: auto;
    float: none;
    margin-right: 60px;

    a {
      display: block;
      width: 100%; } }

  .contact_list {
    display: inline-block;
    padding-left: 60px;
    border-left: solid 1px rgba(255, 255, 255, 0.5);

    h2, p, a {
      color: #4c4d4c; }

    h2 {
      font-size: 20px;
      margin-bottom: 10px; }

    p {
      font-size: 16px;
      margin-bottom: 10px;
      letter-spacing: 1px;

      img {
        display: inline-block;
        margin-right: 10px;
        width: 25px;
        height: auto; }

      a {
        display: inline-block;
        margin-right: 10px; } } }

  .copy {
    position: absolute;
    right: 10%;
    bottom: 40px;
    color: #4c4d4c;
    font-size: 12px;
    text-align: right;

    a {
      color: #4c4d4c; } } }

@media screen and (max-width: 768px) {
  footer#footer {
    padding-bottom: 5px;

    h1.logo {
      float: none;
      margin: 0 auto 30px auto;
      display: table; }

    .contact_list {
      text-align: center;
      display: table;
      padding-left: 0;
      margin: 0 auto;

      h2 {
        font-size: 16px; }

      p {
        font-size: 14px; } }

    .copy {
      position: relative;
      margin-top: 20px;
      bottom: 0;
      right: 0;
      text-align: center; } } }

// header#header
//   background-color: #fff
//   overflow: unset
//   z-index: 10000
//   width: 100%
//   height: auto

//   .container
//     padding: 20px 0
//     width: 90%
//     max-width: 1280px

// h1.logo
//   width: 145px
//   height: auto
//   margin: 0
//   transition-duration: 1s
//   z-index: 2

//   a, img
//     display: block
//     width: 100%
//     height: auto

// #nav_container
//   transition-duration: 1s

//   .nav_list
//     margin: 0
//     padding: 0

//     &:after
//       content: ""
//       display: table
//       clear: both

//     > li
//       &:hover > a
//         color: #cb281c

//       &.drop_menu > a:after
//         content: ""
//         display: block
//         margin-left: 5px
//         width: 6px
//         height: 6px
//         background-image: url("https://ws041.weisonmedia.com.tw/wp-content/uploads/icon_g+.svg")

//   li
//     list-style-type: none

//     a
//       display: block
//       padding: 10px 20px
//       color: #242a64
//       font-size: 18px
//       text-decoration: none
//       letter-spacing: 2px
//       line-height: 18px
//       transition-duration: 0.4s

//       &:hover
//         color: #cb281c

//       svg
//         width: auto
//         height: 14px
//         margin-right: 5px

//   .sec_menu
//     display: none
//     padding: 10px 0
//     margin: 0
//     position: absolute
//     top: 100%
//     left: 0%
//     width: 250px
//     height: auto
//     background-color: rgba(237, 237, 237, 0.8)
//     border: solid 1px #6f6f6f

//     &:after
//       content: ""
//       display: table
//       clear: both
//       content: ""
//       display: block
//       position: absolute
//       bottom: 100%
//       left: 20%
//       width: 0
//       height: 0
//       border: 7px solid transparent
//       border-bottom-color: #4c4d4c

//     li
//       width: 100%

//       a
//         text-align: left
//         display: block
//         font-size: 14px

//       &.drop_menu:hover
//         > a
//           box-shadow: none

//         .sec_menu
//           display: block

//     > li > a
//       padding: 5px 20px

//       &:hover
//         color: #cb281c

//   .thi_menu
//     display: none
//     margin: 0
//     padding: 0
//     position: absolute
//     left: 100%
//     top: 0
//     width: 100%
//     height: auto
//     background-color: #3d5c98

// button#mobile_menu
//   display: none

// @media screen and (min-width: 768px)
//   #nav_container li.drop_menu:hover .sec_menu
//     display: block

//     li.drop_menu:hover .thi_menu
//       display: block

//   header#header:after
//     content: ""
//     display: table
//     clear: both

//   #nav_container
//     .nav_list
//       margin: 0
//       padding: 0

//       &:after
//         content: ""
//         display: table
//         clear: both

//     li
//       list-style-type: none
//       float: left

//   h1.logo
//     float: left
//     margin-right: 40px

//   #nav_container
//     float: left
//     margin-top: 40px

// @media screen and (max-width: 1120px)
//   header#header .container
//     width: 95%

//   #nav_container li a
//     padding-left: 10px
//     padding-right: 10px

// @media screen and (max-width: 1040px)
//   header#header
//     h1.logo
//       left: 30px
//       width: 70px

//     .container
//       width: 100%
//       padding: 10px 0
//       position: unset
//       background-color: #fff

//     .lang_bar
//       right: 80px
//       top: 50%
//       transform: translateY(-50%)

//   .socail_links
//     position: fixed
//     right: 10px
//     top: 40%

//     li
//       float: none
//       margin: 0 0 10px 0

//   #nav_container
//     position: absolute
//     margin-top: 0
//     left: 0
//     top: -100vh
//     transition-duration: 2s
//     width: 100%
//     height: auto
//     background-color: #fff
//     opacity: 0
//     z-index: -1
//     box-shadow: 0 3px 5px #ccc

//     &::-webkit-scrollbar
//       display: none

//     .nav_list
//       display: flex
//       align-items: center
//       justify-content: center
//       padding: 20px 0
//       flex-wrap: wrap
//       box-shadow: 5px 5px 10px #ccc

//       > li
//         float: none
//         width: 100%
//         box-shadow: none !important

//         > a
//           display: flex
//           justify-content: center
//           align-items: center

//         &.drop_menu > a:before
//           content: ""
//           display: block
//           margin-right: 5px
//           width: 6px
//           height: 6px
//           background-image: url("https://ws041.weisonmedia.com.tw/wp-content/uploads/icon_g+.svg")

//     .sec_menu, .thi_menu
//       width: 100%
//       position: relative
//       left: 0
//       transform: unset

//     .sec_menu:after, .thi_menu:after
//       left: unset
//       right: 50%
//       transform: translate(50%, 0)

//     .sec_menu li, .thi_menu li
//       margin-bottom: 0
//       float: none

//     .sec_menu li a, .thi_menu li a
//       display: block
//       text-align: center
//       padding: 10px 0

//     .sec_menu
//       background-color: rgba(237, 237, 237, 0.8)

//     .thi_menu
//       background-color: #fff

//   button#mobile_menu
//     display: block
//     position: absolute
//     bottom: 50%
//     transform: translateY(50%)
//     right: 30px
//     border: none
//     padding: 0
//     width: 30px
//     height: 30px
//     background: transparent

//     span
//       display: block
//       width: 100%
//       height: 3px
//       margin-bottom: 5px
//       background-color: #3d5c98
//       border-radius: 20px
//       transition-duration: 0.4s

//       &:last-child
//         margin-bottom: 0

//     &.open span
//       &:nth-child(2)
//         opacity: 0
//         margin-bottom: 10px

//       &:nth-child(1)
//         transform-origin: 0 50%
//         transform: rotate(45deg)

//       &:nth-child(3)
//         transform-origin: 0 50%
//         transform: rotate(-45deg)

//// body.menu_in #nav_container
////   top: calc(100% + 3px)
////   opacity: 1
////   transition-duration: 1s

//// li.drop_menu.open
////   > a
////     color: #242a64 !important

////   .sec_menu
////     display: block

////     li.drop_menu.open .thi_menu
////       display: block

.lang_bar {
  border: solid 1px #4c4d4c;
  max-width: 120px;
  width: 120px;
  height: 25px;
  border-radius: 30px;
  position: absolute;
  z-index: 2;
  right: 5%;
  top: 20px;
  background-color: #fff;

  span {
    display: block;
    padding-left: 25px;
    line-height: 25px;
    font-size: 14px;
    cursor: pointer; }

  &:before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background-color: #4c4d4c;
    border-radius: 50%;
    position: absolute;
    right: 2.5px;
    bottom: 50%;
    transform: translateY(50%); }

  &:after {
    content: "";
    display: table;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    border-top-width: 7px;
    border-left-width: 4px;
    border-right-width: 4px;
    border-bottom-width: 7px;
    border-top-color: #fff;
    position: absolute;
    bottom: 30%;
    right: 12.5px;
    transform: translate(50%, 50%); }

  .list {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    width: 90%;
    height: auto;
    border: solid 1px #4c4d4c; }

  li {
    list-style-type: none;
    text-decoration: none;

    &.active a {
      cursor: default; }

    a {
      display: block;
      padding: 5px 10px;
      font-size: 14px;
      line-height: 25px;
      text-decoration: none;
      color: #4c4d4c;
      text-align: center;

      &:hover {
        background-color: #4c4d4c;
        color: #fff; } } } }

.map_list {
  &:after {
    content: "";
    display: table;
    clear: both; }

  .map_item {
    width: 33.3%;
    float: left;

    &:after {
      content: "";
      display: block;
      padding-bottom: 80%; }

    .intro {
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(36, 42, 100, 0.6);
      transition-duration: 0.5s;

      img.map, svg.map {
        display: block;
        width: 50px;
        height: 50px;
        position: absolute;
        right: 50%;
        bottom: 100%;
        transform: translate(50%, 50%); }

      h2, p {
        text-align: center;
        color: #fff; }

      h2 {
        margin: 60px 0 30px 0;
        line-height: 25px;
        height: 85px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 20px;

        span {
          display: block; }

        &:after {
          content: "";
          display: block;
          width: 30px;
          height: 2px;
          opacity: 0.5;
          position: absolute;
          top: 100%;
          right: calc(50% - 15px);
          background-color: #fff; } }

      p {
        font-size: 16px; } }

    &:hover .intro {
      background-color: rgba(76, 77, 76, 0.9);

      .svg.map circle {
        fill: #242a64; } } } }

@media screen and (max-width: 1000px) {
  .map_list .map_item .intro h2 {
    margin-bottom: 15px;
    margin-top: 30px; } }

@media screen and (max-width: 768px) {
  .map_list .map_item {
    width: 50%; } }

@media screen and (max-width: 500px) {
  .map_list .map_item {
    width: 100%;

    .intro {
      svg.map {
        position: relative;
        transform: unset;
        right: 0;
        bottom: 0;
        margin: 50px auto 10px auto; }

      h2 {
        font-size: 20px;
        margin-top: 0;
        margin-bottom: 30px;
        height: auto;

        &:after {
          position: absolute;
          top: calc(100% + 15px); } }

      p {
        font-size: 16px; } } } }

.page-about {
  .bg-block {
    display: none; }

  .col-sm-6 .con {
    padding: 30px;
    background-color: #f5f5f5; }

  .col-sm-pull-6 .con {
    padding: 30px; }
  img {
    z-index: 10; } }

@media screen and (min-width: 767px) {
  .page-about .col-sm-6 .con {
    padding: 60px 30px 60px 30vw;
    width: calc(100% + 30vw);
    z-index: 1;
    transform: translate(-30vw, -20px); } }

@media screen and (min-width: 767px) {
  .page-about .col-sm-pull-6 .con {
    padding: 60px 30vw 60px 30px;
    transform: translate(0vw, -20px); } }

@media screen and (max-width: 900px) {
  .page-process [class*="col"] {
    width: 100% !important;
    right: 0;
    left: 0;

    .con {
      transform: none;
      background: rgba(36, 42, 100, 0.9);
      width: 100%;
      margin-bottom: 0;
      color: #fff; } } }

#product {
  background-color: #f5f4f4;

  [class*="col-"] {
    margin-bottom: 30px; }

  .row {
    display: flex;
    flex-wrap: wrap; }

  .intro {
    display: block;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(36, 42, 100, 0.6);
      opacity: 0; }

    &:before {
      content: "";
      display: block;
      padding-bottom: 100%; }

    h2, p {
      z-index: 2;
      color: #fff;
      width: 80%;
      right: 50%;
      transform: translateX(50%);
      font-weight: bold;
      opacity: 0;
      transition-duration: 1s; }

    &:hover {
      h2, p, &:after {
        opacity: 1; } }

    h2 {
      display: flex;
      align-items: center;
      position: absolute;
      top: 25%;
      border-bottom: solid 1px #fff;
      padding-bottom: 10px; }

    p {
      position: absolute;
      top: 45%; }

    &.logo {
      h2 {
        display: block;
        top: 50%;
        transform: translate(50%, -50%);
        border: none;
        padding-bottom: 0;
        text-align: center;
        opacity: 1;
        font-size: 18px;

        img, svg {
          display: block;
          width: 110px;
          margin: 0 auto 0 auto; }

        img path, svg path {
          fill: #fff; } }

      &:after {
        opacity: 1; } } } }

@media screen and (max-width: 850px) {
  #product [class*="col-"] {
    width: 50%; } }

@media screen and (max-width: 767px) {
  #product [class*="col-"] {
    &:nth-of-type(3), &:nth-of-type(6) {
      width: 100%; }

    &:nth-of-type(3) a h2 {
      svg, img {
        width: 80px; } }

    &:nth-of-type(6) a h2 {
      svg, img {
        width: 80px; } }

    &:nth-of-type(3) a:before, &:nth-of-type(6) a:before {
      padding-bottom: 60%; } } }

@media (min-width: 767px) and (max-width: 900px) {
  #product .intro h2 {
    font-size: 16px;
    top: 10%; } }

@media (max-width: 650px) {
  #product .intro h2 {
    border: none;
    display: block;
    text-align: center;
    line-height: 25px;
    font-size: 14px;

    img {
      display: block;
      margin: 0 auto 10px; } } }

@media (min-width: 767px) and (max-width: 900px) {
  #product .intro p {
    font-size: 14px;
    top: 35%; } }

@media (max-width: 650px) {
  #product .intro p {
    display: none; } }

.fadeIn {
  opacity: 0;

  &.kaf_active {
    opacity: 1 !important; } }

.fadeInDown {
  transform: translateY(-100px);
  opacity: 0;

  &.kaf_active {
    opacity: 1 !important;
    transform: translateY(0); } }

.fadeInUp {
  transform: translateY(100px);
  opacity: 0;

  &.kaf_active {
    opacity: 1 !important;
    transform: translateY(0); } }

.fadeInLeft {
  transform: translateX(-50px);
  opacity: 0;

  &.kaf_active {
    opacity: 1 !important;
    transform: translateX(0); } }

.fadeInRight {
  transform: translateX(50px);
  opacity: 0;

  &.kaf_active {
    opacity: 1 !important;
    transform: translateX(0); } }

.block_in {
  img {
    opacity: 0;
    transition-delay: 0.7s; }

  &:before {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-color: #3d5c98;
    z-index: 5;
    width: 100%;
    height: 100%;
    transform: scaleX(0); }

  &.kaf_active {
    img {
      opacity: 1; }

    &:before {
      animation: block_in 1.5s forwards; } } }

// .owl-carousel .owl-controls
//   position: absolute
//   bottom: 20px
//   right: 0
//   z-index: 2
//   width: 100%

//   .owl-dot
//     span
//       background-color: #fff
//       opacity: 1
//       border: solid 1px #4c4d4c

//     &.active span
//       background-color: #4c4d4c
//       border: solid 1px #fff

// @media screen and (max-width: 600px)
//   .owl-carousel .owl-controls
//     bottom: 5px

.page-container {
  padding: 70px 0;

  .photo-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    background-color: rgba(36, 42, 100, 0.9);
    margin-bottom: 50px;

    a {
      display: block;
      margin: 0 30px;
      border-bottom: solid 1px transparent;
      font-size: 18px;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
      color: #fff;
      transition-duration: 1s;
      text-decoration: none;

      &.active, &:hover {
        border-bottom: solid 1px #fff; } } } }

.service-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;

  &.video a {
    background-size: contain; }

  a {
    display: block;
    width: calc(100% / 3 - 31px);
    margin: 0 15px 55px 15px;

    p {
      padding: 10px 0 0 0;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      text-align: center;
      color: rgba(36, 42, 100, 0.9);
      font-size: 16px; }

    &:after {
      content: "";
      display: block;
      padding-bottom: 75%; } } }

@media screen and (max-width: 600px) {
  .service-row {
    margin: 0 -10px; } }

@media screen and (max-width: 600px) {
  .service-row a {
    width: calc(50% - 21px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 45px; } }

.fancybox-button--share {
  display: none; }

.fancybox-navigation {
  position: initial; }

.fancybox-slide--iframe .fancybox-content {
  margin: 0;
  height: 0;
  padding-bottom: 45%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; } }



/*# sourceMappingURL=style.min.css.map */
