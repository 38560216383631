.btn, .mbtn {
  border: 2px solid #769ba4;
  color: #769ba4;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s; }

.btn:hover, .mbtn:hover {
  background: #769ba4;
  color: #fff;
  text-decoration: none; }

.mpgbar {
  margin-bottom: 30px; }

.page-bn {
  position: relative;

  h2 {
    font-size: 30px;
    position: absolute;
    top: 50%;
    text-align: center;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #fff;
    z-index: 10;

    span {
      display: block;
      margin-top: 10px; } }

  img {
    width: 100%; }

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 2; } }

.page-news {
  h2.title {
    margin-top: 30px; }

  .row {
    margin-bottom: 30px; }

  .mbox {
    position: relative;
    overflow: hidden;
    padding-bottom: 0;

    &:hover .d-txt {
      bottom: 0;
      background: #769ba4b6; } }

  .d-txt {
    background: #769ba4;
    bottom: -50px;
    position: absolute;
    width: 100%;
    padding: 10px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;

    .txt p {
      color: #fff; }

    .mdate {
      color: #fff;
      display: block; }

    a {
      color: #fff; } } }

.page_mobile_mecprod .mbox, .page_mobile_meccg .mbox {
  margin-bottom: 20px; }

.page_mobile_mecprod .mbox .d-img, .page_mobile_meccg .mbox .d-img {
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s; }

.page_mobile_mecprod .mbox:hover .d-img, .page_mobile_meccg .mbox:hover .d-img {
  border: 1px solid #ddd; }

.page_mobile_mecprod .mbox .d-txt, .page_mobile_meccg .mbox .d-txt {
  text-align: center;
  margin-top: 10px; }

.page_mobile_mecprod .mt h3, .page_mobile_meccg .mt h3 {
  border-bottom: 0;
  margin: 0;
  color: #fff;
  background: rgba(36, 42, 100, 0.9);
  margin-bottom: 20px;
  font-weight: bold; }

.page_mobile_mecprod .msearch, .page_mobile_meccg .msearch {
  margin: 20px 0; }

.page_mobile_mecprod .drop, .page_mobile_meccg .drop {
  margin-bottom: 10px; }

.page_mobile_mecprod .drop > a, .page_mobile_meccg .drop > a {
  display: block;
  background: rgba(36, 42, 100, 0.9);
  color: #fff;
  padding: 10px;
  cursor: pointer;
  font-size: 16px; }

.page_mobile_mecprod .menu-list h3, .page_mobile_meccg .menu-list h3 {
  color: #242a64; }

.page_mobile_mecprod .sec-menu, .page_mobile_meccg .sec-menu {
  background: #f0f0f0;
  padding: 20px;
  display: none; }

.page_mobile_mecprod .sec-menu li, .page_mobile_meccg .sec-menu li {
  margin-bottom: 10px; }

.page_mobile_mecprod .mrow, .page_mobile_meccg .mrow {
  max-width: 1200px;
  width: 100%;
  margin: 30px auto;
  padding: 0 20px; }

.page_mobile_mecprod .sec-menu a {
  color: #1A2731;
  font-size: 16px;
  &:hover {
    text-decoration: underline; } }

.page-process {
  h2.title {
    margin-top: 30px; }

  .con {
    background: rgba(36, 42, 100, 0.9);
    padding: 20px;
    color: #fff;
    line-height: 32px;
    z-index: 1;

    h3 {
      margin-bottom: 20px;
      color: #fff; } } }

.Applications {
  h2.title {
    margin-top: 30px; }

  .con {
    background: #f2f2f2;
    padding: 80px 20px;

    p {
      max-width: 900px;
      width: 100%;
      margin: 0 auto;
      line-height: 32px; } }

  .col-sm-3 {
    padding: 0;
    position: relative;

    .txt {
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      padding-top: 50px;
      background: rgba(36, 42, 100, 0.6);

      a {
        color: #fff; }

      h2 {
        font-size: 18px;
        border-bottom: 1px solid #fff;
        margin: 20px;
        padding-bottom: 10px;
        color: #fff;

        span {
          display: block;
          margin-top: 6px; } }

      p {
        font-size: 14px;
        color: #fff;
        margin: 20px; } }

    &.last .txt {
      background: rgba(36, 42, 100, 0.9);
      opacity: 1 !important; } } }

.page-contact {
  h2.title {
    margin-top: 30px; }

  .con {
    padding: 30px;

    h3 {
      margin-bottom: 15px; }

    p {
      line-height: 32px; } }

  .row {
    background: #f3f3f3; }

  .col-sm-6 {
    padding: 0; } }

.page-about {
  .bg-block {
    width: calc(50vw + 360px);
    left: auto;
    right: 100%;
    height: 25.7vw;
    max-height: 360px;
    -webkit-transform: translate3d(15vw, -40px, 0);
    transform: translate3d(15vw, -40px, 0);
    background-color: #f5f5f5;
    position: absolute; }

  .row {
    margin: 60px 0; }

  h2.title {
    margin-top: 30px; }

  .con {
    position: relative;

    h3 {
      margin-bottom: 15px;
      color: #000; }

    p {
      line-height: 32px;
      font-size: 18px;
      text-align: justify;
      color: #444;
      font-weight: normal; } }

  .col-sm-8 .con {
    padding: 30px; }

  .con-bg {
    background-color: #f5f5f5;
    padding: 30px;
    margin-top: 60px; } }

@media (min-width: 768px) {
  .page-contact .row {
    margin: 60px 0; }

  .Applications {
    .con {
      margin-bottom: 15px; }

    .container {
      -webkit-transform: translateY(-70px);
      -ms-transform: translateY(-70px);
      transform: translateY(-70px); }

    .col-sm-3 {
      .txt {
        opacity: 0; }

      &:hover .txt {
        opacity: 1; } } }

  .page-process {
    .row {
      margin: 60px 0; }

    .row-left .con {
      -webkit-transform: translate(100px, 100px);
      -ms-transform: translate(100px, 100px);
      transform: translate(100px, 100px);
      z-index: 1;
      width: 500px; }

    .row-right {
      margin-bottom: 140px;

      .con {
        -webkit-transform: translate(-100px, 100px);
        -ms-transform: translate(-100px, 100px);
        transform: translate(-100px, 100px);
        width: 500px; } } }

  .page-news {
    .row {
      margin: 30px 0; }

    .d-item {
      width: calc(100% / 3 - 40px);
      margin: 0 20px; } } }

@media (max-width: 1100px) {
  .Applications .col-sm-3 .txt {
    padding-top: 10px; }

  .page-process {
    .row-left .con {
      -webkit-transform: translate(0px, 0px);
      -ms-transform: translate(0px, 0px);
      transform: translate(0px, 0px); }

    .row-right .con {
      -webkit-transform: translate(-177px, 0px);
      -ms-transform: translate(-177px, 0px);
      transform: translate(-177px, 0px); } } }

@media (max-width: 767px) {
  .page-contact .row {
    margin-bottom: 30px; }

  .Applications {
    .con {
      margin-bottom: 15px;
      padding: 20px; }

    .col-sm-3 {
      margin-bottom: 15px;

      .txt {
        padding-top: 50px; } } }

  .page-process {
    .row-right .con {
      -webkit-transform: translate(0px, 0px);
      -ms-transform: translate(0px, 0px);
      transform: translate(0px, 0px); }

    .row {
      margin-bottom: 30px;
      margin-left: 0;
      margin-right: 0; }

    .con {
      background: transparent;
      margin-bottom: 20px;
      color: #000; } }

  .page_mobile_mecprod .d-item, .page_mobile_meccg .d-item {
    margin-bottom: 20px; }

  .page_mobile_mecprod .mt h3, .page_mobile_meccg .mt h3 {
    background: transparent;
    color: #242a64; }

  .page_mobile_mecprod .mrow, .page_mobile_meccg .mrow {
    padding: 0 20px; }

  .page-news {
    .d-txt {
      position: static; }

    .d-item {
      margin-bottom: 20px; } }

  .page-bn h2 {
    font-size: 20px;

    span {
      display: block;
      margin-top: 10px; } }

  .page-about {
    .row {
      margin: 30px 0; }

    .con {
      margin-top: 20px; }

    .col-sm-8 .con {
      padding: 0px; } } }
