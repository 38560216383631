// HEADER
.sf-arrows .sf-with-ul:after {
  display: none; }

h1.site-logo {
  margin: 0 40px 0 0;
  a {
    display: block;
    width: 145px;
    img {
      width: 100%;
      height: auto; } } }

li.cn-line, li.cn-fb {
  display: none; }


@media screen and (max-width: 768px) {
  h1.site-logo {
    margin-top: 13px;
    a {
      width: 70px; } } }

#header-container {
  padding: 20px 0;
  width: 90%;
  max-width: 1280px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative; }
@media screen and (max-width: 767px) {
  #header-container {
    padding: 0 0 20px 0; } }
#main-nav-container {
  &::after {
    content: '';
    display: block;
    clear: both; } }

.socail_links {
  padding: 0;
  margin: 0;
  position: absolute;
  right: 5.5%;
  li.wechat {
    cursor: pointer; }

  &:after {
    content: "";
    display: table;
    clear: both; }

  li {
    margin: 0 5px;
    float: left;
    width: 30px;
    height: 30px;

    a, img {
      width: 30px;
      height: 30px; } } }

.main-nav-right {
  #main-nav {
    float: left;
    > li {
      a {
        display: block;
        padding: 10px 20px;
        color: #242a64;
        font-size: 18px;
        text-decoration: none;
        letter-spacing: 2px;
        line-height: 18px;
        font-weight: 400;
        transition: .2s linear;
        &:hover {
          color: #CB281C; } } } } }



#mobile-nav-toggle {
  display: none; }

@media screen and (max-width: 767px) {

  #mobile-nav-toggle {
    top: 25px;
    display: block;
    position: absolute;
    padding: 0;
    width: 30px;
    height: 30px;
    i {
      display: none; }
    span {
      display: block;
      width: 100%;
      height: 3px;
      margin-bottom: 5px;
      background-color: #3d5c98;
      border-radius: 20px;
      transition-duration: .4s;
      &:last-child {
        margin-bottom: 0; } }
    &.open {
      span {
        &:nth-child(2) {
          transform-origin: 0 50%;
          transform: rotate(45deg); }
        &:nth-child(3) {
          opacity: 0;
          margin-bottom: 10px; }
        &:nth-child(4) {
          transform-origin: 0 50%;
          transform: rotate(-45deg); } } } } }
#main-nav {
  ul {
    padding: 10px 0;
    margin: 0;
    position: absolute;
    top: 100%;
    left: 0%;
    height: auto;
    background-color: rgba(237,237,237,0.8);
    border: solid 1px #6f6f6f;
    li {
      background: transparent;
      border-top: unset;
      &:hover {
        background: none; }
      a {
        transition: .2s linear;
        &:hover {
          color: #CB281C; } } }


    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 100%;
      left: 20%;
      width: 0;
      height: 0;
      border: 7px solid transparent;
      border-bottom-color: #4c4d4c; } } }

#header-container > li:first-child {
  border: solid 1px #4c4d4c;
  max-width: 120px;
  width: 120px;
  height: 25px;
  border-radius: 30px;
  position: absolute;
  z-index: 2;
  right: 6%;
  top: 20px;
  background-color: #fff;
  list-style-type: none;
  padding-left: 24px;
  &::after {
    content: '';
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    width: 18px;
    height: 18px;
    background-color: #4c4d4c;
    border-radius: 50%;
    color: #fff; }
  &::before {
    content: "";
    display: table;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    border-top-width: 5px;
    border-left-width: 3px;
    border-right-width: 3px;
    border-bottom-width: 5px;
    border-top-color: #fff;
    position: absolute;
    bottom: 36%;
    right: 14.5px;
    transform: translate(50%, 50%);
    z-index: 10; }


  a.sf-with-ul {
    font-size: 14px;
    color: #444; }
  .sub-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    width: 90%;
    height: auto;
    border: solid 1px #4c4d4c;
    text-align: center;
    li {
      a {
        display: block;
        padding: 5px 10px;
        font-size: 14px;
        line-height: 25px;
        text-decoration: none;
        color: #4c4d4c;
        text-align: center;
        &:hover {
          background-color: #4c4d4c;
          color: #fff; } } } } }
@media screen and (max-width: 767px) {
  #header-container > li:first-child {
    top: 50%;
    right: 50px;
    transform: translateY(-50%); } }





@media screen and (max-width: 992px) {
  .socail_links {
    position: fixed;
    right: 10px;
    top: 40%;
    z-index: 100;
    li {
      float: none;
      margin: 0 0 10px 0; } } }
#header {
  z-index: 70; }
@media screen and (max-width: 767px) {
  #header {
    border-bottom: 1px solid #ddd; }

  #mobile-nav {
    // position: fixed
    // top: 0
    // padding-top: 18px
    // bottom: 0
    // z-index: 998
    // background: #fff
    // left: -260px
    // width: 260px
    // overflow-y: auto
    // transition: 0.4s

    position: absolute;
    margin-top: 0;
    left: 0;
    top: -100vh;
    bottom: auto;
    transition-duration: 2s;
    width: 100%;
    height: auto;
    background-color: #fff;
    opacity: 0;
    z-index: -1;
    box-shadow: 0 3px 5px #ccc;
    > ul {
      > li:last-child {
        display: none; } } }

  #mobile-body-overly {
    display: none !important; }


  body.mobile-nav-active {
    overflow: auto;
    #mobile-nav {
      // left: 0
      top: 67px;
      opacity: 1;
      transition-duration: 1s; } } }

#mobile-nav ul .menu-item-has-children i {
  opacity: 0; }
#mobile-nav {
  z-index: 50;

  > ul {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    flex-wrap: wrap;
    box-shadow: 5px 5px 10px #ccc;
    > li {
      float: none;
      width: 100%;
      text-align: center;
      a {
        color: #242a64;
        font-size: 18px;
        letter-spacing: 2px; } } }

  .sub-menu {
    background-color: rgba(237,237,237, .8);
    border: solid 1px #6f6f6f;
    position: relative;
    li {
      padding-left: 0; }
    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translatex(-50%);
      width: 0;
      height: 0;
      border: 7px solid transparent;
      border-bottom-color: #4c4d4c; }
    a {
      color: #242a64;
      font-size: 14px;
      letter-spacing: 2px; } }

  #mobile-nav ul .menu-item-has-children i {
    opacity: 0; } }
